/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./Logo.vue?vue&type=template&id=7264fc6a&scoped=true"
import script from "./Logo.vue?vue&type=script&lang=js"
export * from "./Logo.vue?vue&type=script&lang=js"
import style0 from "./Logo.vue?vue&type=style&index=0&id=7264fc6a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.8.1_prettier@3.1.1_vue-template-compiler@2.6.14_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7264fc6a",
  null
  
)

export default component.exports