/**
 * 景区售票端接口
 */
import request from "@/utils/request";

// 查询售票点列表
export function listWicket(query) {
  return request({
    url: "/office/saleTicket/wicketList",
    method: "post",
    data: query,
  });
}

/**
 * 查询售票信息
 * ticketOfficeId: 售票点id
 */
export function getTicketByOffice(query) {
  return request({
    url: "/office/saleTicket/getTicketByOffice",
    method: "post",
    data: query,
  });
}

/**
 * @typedef Bill
 * @property {string} scenicId 景区id
 * @property {string} scenicName 景区名称
 * @property {string} ticketOfficeId 售票点id
 * @property {string} ticketOfficeName 售票点名称
 * @property {string} ticketWindowId 售票窗口id
 * @property {string} ticketWindowName 售票窗口名称
 * @property {string} ticketTag 门票标签 票类
 * @property {string} operationId 门票业务id
 * @property {string} operationName 门票业务名称
 * @property {string} operationType 门票业务类型
 * @property {string} buyName 购票人名称
 * @property {string} buyPhone 购票人手机
 * @property {string} memberId 购票会员ID
 * @property {string} memberName 购票会员名称
 * @property {number} orderType 订单类型 1 售票 2 补录 3退票
 * @property {string} sellerId 售票员id
 * @property {string} userName 售票员账号
 * @property {string} nickName 售票员昵称
 * @property {number} seqNO 订单明细序号
 * @property {string} allTicketNo 所有票号
 * @property {string} sTicketNo 票号开始票号
 * @property {string} eTicketNo 票号结束票号
 * @property {number} numberRule 票号规则长度
 * @property {string} ticketId 票类 门票ID
 * @property {string} ticketName 票类 门票名称
 * @property {string} sTicketValidity 门票有效期开始
 * @property {string} eTicketValidity 门票有效期结束
 * @property {number} ticketNum 门票张数
 * @property {number} personNum 门票人数
 * @property {number} ticketOriginalPrice 门票原单价
 * @property {number} ticketDiscountPrice 门票折后单价
 * @property {number} ticketTotalMoney 门票总金额
 * @property {number} orderTotalMoney 订单总金额
 * @property {number} orderAmount 订单总价、收款金额
 * @property {number} orderChangeMoney 找零金额
 * @property {string} payWayInfo 结算明细
 * @property {string} payTime 支付时间
 * @property {string} payWayId 支付方式id
 * @property {string} payWayName 支付方式名称
 * @property {string} remark 备注
 * @property {User} user 临时销售员对象
 * @property {string} cBeginTime 临时搜索开始时间
 * @property {string} cEndTime 临时搜索结束时间
 * @property {string} saleNo 原销售单号，退款用
 * @property {string} shopId
 * @property {string} shopName
 * @property {string} changeValidity 是否手动修改了有效期 "0" 未修改 "1" 已修改，初始值为null
 * @property {Array[Ticket]} tickets 临时门票信息
 */

/**
 * @typedef Ticket
 * @property {string} ticketTag 门票标签 票类
 * @property {string} operationId 门票业务id
 * @property {string} operationName 门票业务名称
 * @property {string} operationType 门票业务类型
 * @property {number} buyTicketNum 购票张数
 * @property {number} buyPersonNum 门票人数
 * @property {string} id 门票ID
 * @property {string} ticketName 票类 门票名称
 * @property {string} sTicketValidity 门票有效期开始
 * @property {string} eTicketValidity 门票有效期结束
 * @property {string} sellingPrice 售价
 * @property {string} ticketDiscountPrice 临时门票折后单价
 * @property {string} ticketTotalMoney 临时门票总金额
 */

/**
 * 创建订单
 * @param {Bill} data
 */
export function createBill(data) {
  data.orderSource = "窗口";
  return request({
    url: "/office/saleTicket/createBill",
    method: "post",
    data,
  });
}

/**
 * @typedef PayData
 * @property {string} orderNo 订单编号
 * @property {string} payWayId 支付方式id
 * @property {string} payWayName 支付方式名称
 * @property {number} orderAmount 收款金额
 * @property {number} orderChangeMoney 找零金额
 */

/**
 * 支付门票订单
 * @param {PayData} data
 */
export function toPay(data) {
  return request({
    url: "/office/saleTicket/toPay",
    method: "post",
    data,
  });
}

/**
 * @typedef GetSaleHistoryParams
 * @property {string} startTime 临时搜索开始时间
 * @property {string} endTime 临时搜索结束时间
 * @property {number} orderType 订单类型 1 售票 2 补录 3退票
 * @property {string} sellerId 售票员id /getInfo userId
 * @property {number} orderState 订单状态 0 未付 1 已付 2 退款中 3 已退
 */

/**
 * 获取售卖记录
 * @param {PayData} data
 */
export function getSaleHistory(query) {
  return request({
    url: "/office/saleTicket/getSaleHistory",
    method: "get",
    params: query,
  });
}

/**
 * 获取购票记录
 */
export function getRefundHistory(data) {
  return request({
    url: "/ticket/orderInfo/refundHistory",
    method: "get",
    params: data,
  });
}

/**
 * @typedef QueryByTicketNoParams
 * @property {string} ticketNo 票号
 */

/**
 * 查询门票信息
 * @param {QueryByTicketNoParams} data
 */
export function queryByTicketNo(data) {
  return request({
    url: "/office/saleTicket/queryByTicketNo",
    method: "post",
    data,
  });
}

/**
 * @typedef GetDevicesParams
 * @property {string} userId 用户ID
 * @property {number} state 启用状态 0 启用 1 禁用
 * @property {number} ticketCheckingEquipmentType 设备类型
 */

/**
 * 获取设备
 * @param {GetDevicesParams} data
 */
export function getDevices(data) {
  return request({
    url: "/office/saleTicket/getDevices",
    method: "post",
    data,
  });
}

/**
 * @typedef QueryInfoParams
 * @property {string} ticketNo 票号
 * @property {number} ticketCounterId 检票点id
 */

/**
 * 检票时查询信息
 * @param {QueryInfoParams} data
 */
export function queryInfo(data) {
  return request({
    url: "/office/saleTicket/queryInfo",
    method: "post",
    data,
  });
}

/**
 * @typedef QueryRefundTicketParams
 * @property {string} startTicketNo 开始票号
 * @property {string} endTicketNo 结束票号
 */

/**
 * 检票时查询信息
 * @param {QueryRefundTicketParams} data
 */
export function queryRefundTicket(data) {
  return request({
    url: "/office/saleTicket/checkRefundTicket",
    method: "post",
    data,
  });
}

/**
 * 创建退票订单
 * @param {Bill} data
 */
export function createRefundBill(data) {
  return request({
    url: "/office/saleTicket/createRefundBill",
    method: "post",
    data,
  });
}

/**
 * 退款
 * @param {PayData} data
 */
export function payRefundBill(data) {
  return request({
    url: "/office/saleTicket/payRefundBill",
    method: "post",
    data,
  });
}

/**
 * @typedef CheckedTicketParams
 * @property {string} ticketNo 票号
 * @property {string} ticketId 门票id
 * @property {number} useType 使用类型  1 售票 2 验票进 3 验票出 4 退票
 * @property {number} checkedNum 检票次数
 * @property {string} deviceId 售票和退票就是售票窗口id，验票就是设备id
 * @property {string} deviceName 售票和退票就是售票窗口名称，验票就是设备名称
 * @property {string} ticketCounterId 检票点id
 * @property {string} ticketCounterName 检票点名称
 * @property {string} sellerId 操作人id
 * @property {string} userName 操作人账号
 * @property {string} nickName 操作人名称
 * @property {number} ifDevice 是否闸机  0否  1是
 */

/**
 * 检票/验票
 * @param {CheckedTicketParams} data
 */
export function checkedTicket(data) {
  data.ifDevice = 0;
  return request({
    url: "/office/saleTicket/checkedTicket",
    method: "post",
    data,
  });
}
