//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import logoImg from "@/assets/logo/logo.png";
import variables from "@/assets/styles/variables.scss?modules=true";
import { mapGetters } from "vuex";
export default {
  name: "SidebarLogo",
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      logo: logoImg
    };
  },
  computed: {
    ...mapGetters(["shopName"]),
    title() {
      return this.shopName ?? process.env.VUE_APP_TITLE;
    },
    variables() {
      return variables;
    },
    sideTheme() {
      return this.$store.state.settings.sideTheme;
    }
  }
};