/* unplugin-vue-components disabled */import __unplugin_components_1 from 'element-ui/lib/menu';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/menu.css';
import __unplugin_components_0 from 'element-ui/lib/scrollbar';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/scrollbar.css';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'has-logo': _vm.showLogo },style:({
    backgroundColor:
      _vm.settings.sideTheme === 'theme-dark'
        ? _vm.variables.menuBackground
        : _vm.variables.menuLightBackground,
  })},[(_vm.showLogo)?_c('logo',{attrs:{"collapse":_vm.isCollapse}}):_vm._e(),_c(__unplugin_components_0,{class:_vm.settings.sideTheme,attrs:{"wrap-class":"scrollbar-wrapper"}},[_c(__unplugin_components_1,{attrs:{"default-active":_vm.activeMenu,"collapse":_vm.isCollapse,"background-color":_vm.settings.sideTheme === 'theme-dark'
          ? _vm.variables.menuBackground
          : _vm.variables.menuLightBackground,"text-color":_vm.settings.sideTheme === 'theme-dark'
          ? _vm.variables.menuColor
          : _vm.variables.menuLightColor,"unique-opened":true,"active-text-color":_vm.settings.theme,"collapse-transition":false,"mode":"vertical"}},_vm._l((_vm.sidebarRouters),function(route,index){return _c('sidebar-item',{key:route.path + index,attrs:{"item":route,"base-path":route.path}})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }