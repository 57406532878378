//
//
//
//
//
//
//
//
//
//
//
//

import InnerLink from "../InnerLink/index";
export default {
  components: {
    InnerLink
  },
  computed: {
    iframeViews() {
      return this.$store.state.tagsView.iframeViews;
    }
  }
};