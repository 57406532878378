import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import variables from "@/assets/styles/variables.scss?modules=true";
import Item from "./Item";
export default {
  name: "DouyinOtaSidebar",
  components: {
    SidebarItem,
    Logo,
    Item
  },
  data() {
    return {
      sidebarRouters: [
      // {
      //   path: "/ticket/ota_douyin/AccountAuthDetails",
      //   hidden: false,
      //   meta: {
      //     title: "账户授权详情",
      //     icon: "account-auth",
      //   },
      // },
      {
        path: "/ticket/ota_douyin/TicketAssociationSettings",
        hidden: false,
        meta: {
          title: "票类关联设置",
          icon: "ticket-association-settings"
        }
      }, {
        path: "/ticket/ota_douyin/TakeTicketRecord",
        hidden: false,
        meta: {
          title: "取票记录",
          icon: "collection-record"
        }
      }, {
        path: "/ticket/ota_douyin/CheckTicketRecord",
        hidden: false,
        meta: {
          title: "核销记录",
          icon: "ota-check-record"
        }
      }]
    };
  },
  computed: {
    ...mapState(["settings"]),
    ...mapGetters(["sidebar"]),
    /**
     * 返回首页时，清空tab
     */
    activeMenu() {
      const route = this.$route;
      const {
        meta,
        path
      } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    }
  },
  methods: {
    goHome() {
      this.$tab.closeAllPage();
      this.$router.push({
        name: "Index"
      });
    }
  }
};