//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    src: {
      type: String,
      default: "/"
    },
    iframeId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loading: false,
      height: document.documentElement.clientHeight - 94.5 + "px;"
    };
  },
  mounted() {
    const iframeId = ("#" + this.iframeId).replace(/\//g, "\\/");
    const iframe = document.querySelector(iframeId);
    // iframe页面loading控制
    if (iframe.attachEvent) {
      this.loading = true;
      iframe.attachEvent("onload", () => {
        this.loading = false;
      });
    } else {
      this.loading = true;
      iframe.onload = () => {
        this.loading = false;
      };
    }
  }
};